<template>
  <div class="wechat-page">
    <div class="container">
      <!-- <div
        v-if="linkData.ProtType == 3 || linkData.ProtType == 7"
        class="wechat-image-page"
        @touchstart.prevent="onTouchStart"
        @touchend.prevent="onTouchEnd"
      >
        <img
          :src="'https://cyeassws.cdyxd.top/' + linkData.Params.ImgUrl"
          class="wechat-image"
          alt="WeChat Image"
        />
        <div v-if="showSaveHint" class="save-hint">长按保存图片</div>
      </div> -->
      <div>
        <img src="/wechat_logo.png" alt="WeChat Logo" class="wechat-logo" />
        <button @click="navigateToMiniProgram" class="button">
          <img
            src="/wechat_mini_program_icon.png"
            alt="WeChat Mini Program Icon"
            class="icon"
          />
          打开微信小程序
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      params: {}, // 接收参数值
      linkData: {}, // 接收小程序链接数据
      showSaveHint: false,
      touchTimer: null,
    };
  },
  created() {
    var parameterValue = this.getUrlParameter("query");
    console.log("query参数值：" + parameterValue);
    if (parameterValue) {
      Object.assign(
        this.params,
        JSON.parse(decodeURIComponent(parameterValue))
      ); // 将参数值赋值给params对象
      console.log(this.params);
    }
    if (this.params.WxExtLinkID) {
      axios
        // http://127.0.0.1:9992/
        // https://cyeassws.cdyxd.top/
        .get(
          "https://cyeassws.cdyxd.top/api/CyEassWs_WxExtLink/GetLinkByVisitor?WxExtLinkID=" +
            this.params.WxExtLinkID
        )
        .then((result) => {
          // 处理响应数据
          if (result.status) {
            this.linkData = JSON.parse(result.data.data).data;
            if (this.linkData.ParamsJson != "") {
              // 需要的json字符串转化为对象
              this.linkData.Params = JSON.parse(this.linkData.ParamsJson);
              this.dyLinkDescribe();
            }
          }
        })
        .catch((error) => {
          // 处理错误
          console.error("Error fetching data:", error);
        });
    } else {
      this.linkData = this.params;
      this.linkData.ProtType = 8;

      console.log("自带参数", this.linkData);
      this.dyLinkDescribe();
    }

    // let iconUrl = "/Template/SysResources/数字店通.jpg";
    // let icon = document.querySelector('link[rel="icon"]');
    // if (icon) {
    //   icon.setAttribute("href", iconUrl);
    // }

    // let linkIcon = document.querySelector('link[rel="shortcut icon"]');
    // if (linkIcon) {
    //   linkIcon.setAttribute("href", this.http.ipAddress + iconUrl);
    // }

    // let alinkIcon = document.querySelector('link[rel="apple-touch-icon"]');
    // if (alinkIcon) {
    //   alinkIcon.setAttribute("href", this.http.ipAddress + iconUrl);
    // }
  },
  mounted() {
    this.showSaveHint = true; // 页面加载时显示提示
  },
  methods: {
    dyLinkDescribe() {
      console.log("linkData修改描述", this.linkData);
      if (this.linkData.Title) {
        // 修改页面标题
        document.title = this.linkData.Title;
      }
      if (this.linkData.Content) {
        // 修改描述
        let metaDescription = document.querySelector(
          'meta[name="description"]'
        );
        if (metaDescription) {
          metaDescription.setAttribute("content", this.linkData.Content);
        }
      }
    },

    getUrlParameter(name) {
      name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
      var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
      var results = regex.exec(location.search);
      return results === null
        ? ""
        : decodeURIComponent(results[1].replace(/\+/g, " "));
    },
    onTouchStart() {
      this.touchTimer = setTimeout(() => {
        this.showSaveHint = true;
        this.downloadIamge();
      }, 500); // 设置长按时间阈值，单位：毫秒
    },
    onTouchEnd() {
      clearTimeout(this.touchTimer);
      this.showSaveHint = false;
    },
    downloadIamge() {
      if (!window.plus) return;
      plus.gallery.save(
        this.imgUrl,
        function () {
          alert("保存成功");
          console.log("保存成功");
        },
        function () {
          alert("保存失败");
          console.log("保存失败");
        }
      );
    },

    navigateToMiniProgram() {
      let url = `weixin://dl/business/?appid=wx8c8ae85aa270e41c&path=pages/public/login&query=scene%3D`;
      switch (this.linkData.ProtType) {
        case 1:
          // 店铺展示链接
          url += `t/11*g/${this.linkData.GroupId}`;
          break;
        case 2:
          // 商品
          url += `t/170*g/${this.linkData.GroupId}*s/${this.linkData.Params.ServiceID}`;
          break;
        case 3:
          url += `t/210*url/${this.linkData.Params.ImgUrl.replaceAll(
            /\//g,
            "~"
          )}`;
          break;
        case 4:
          // 新客户抽奖
          url += `t/190*g/${this.linkData.GroupId}`;
          break;

        case 5:
          // 活动抽奖
          url += `t/180*g/${this.linkData.GroupId}*l/${this.linkData.Params.LotteryActivityID}`;
          break;
        case 6:
          // 渠道
          url += `groupId/${this.linkData.GroupId}*sharerUserId/${this.linkData.Params.SharerUserId}`;
          break;
        case 7:
          url += `t/220*url/${this.linkData.Params.ImgUrl.replaceAll(
            /\//g,
            "~"
          )}`;
          break;
        case 8:
          console.log("paramsparamsparams参数", this.linkData);
          // 软件超市、产品活动推广码
          url += this.linkData.Scene;
          break;
        case 9:
          // 礼品券
          url += `t/260*g/${this.linkData.GroupId}*c/${this.linkData.Params.GiftCouponID}`;
          break;
      }
      console.log("跳转链接：" + url);
      window.location.href = url;
    },
  },
};
</script>

<style scoped>
.wechat-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.container {
  text-align: center;
}

.wechat-logo {
  width: 300px;
  height: 250px;
}

.button {
  background-color: #07c160;
  color: #fff;
  padding: 20px 40px;
  border: none;
  border-radius: 4px;
  font-size: 36px;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}

.icon {
  width: 60px;
  height: 60px;
  margin-right: 10px;
}

.button:hover {
  background-color: #05a049;
}

.wechat-image-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.wechat-image {
  max-width: 100%;
  max-height: 100%;
}

.save-hint {
  position: absolute;
  top: 20px; /* 将提示文字放在顶部 */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 10px 20px; /* 增大提示文字的内边距 */
  border-radius: 10px; /* 增大提示文字的圆角 */
  font-size: 16px; /* 增大提示文字的字体大小 */
}
</style>